import React, { createContext, useContext, useEffect } from 'react';
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react';

const WalletContext = createContext();

export const useWallet = () => {
  return useContext(WalletContext);
};

export const WalletProvider = ({ children }) => {
  const { address: walletAddress, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  const connectWallet = async () => {
    try {
      if (!isConnected) {
        await open();  // Open the modal only when user clicks the button
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  const value = {
    walletAddress,
    isConnected,
    connectWallet,
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
};
