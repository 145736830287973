import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider } from 'ethers';
import { SiweMessage } from 'siwe';
import axios from 'axios';
import { useAuth } from '../Contexts/AuthContext';
import { useWallet } from '../Context/WalletContext';  // Import the WalletContext hook
import './Login.scss';

export default function Login() {
  const [inviteCode, setInviteCode] = useState('')
  const location = useLocation();
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const { login } = useAuth()
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    inviteLinkError: false,
    inviteCodeError: false,
  });
  const [formData, setFormData] = useState({
    code: ''
  });
  const { connectWallet } = useWallet();  // Access the wallet context


  const handleInviteCodeChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setInviteCode(value);
  };

  const handleInviteCodeSubmit = (event) => {
    console.log('submitting inviteCode: ' + inviteCode)
    event.preventDefault();
    if (inviteCode) {
      loginReferral();
    }
  };

  const closeModal = () => {
    setModal(prevState => ({
      ...prevState,
      inviteLinkError: false,
      inviteCodeError: false,
    }));

    setFormData({
      code: ''
    });

    setInviteCode('');
  };

  const loginReferral = async () => {

    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()

    try {
      setLoading(true);

      //create the siwe message
      const message = new SiweMessage({
        domain: window.location.origin,
        address: address,
        statement: 'Sign in with Ethereum to zkCandy Points Portal',
        uri: "https://portal.zkcandy.io",
        version: '1',
        chainId: "302",
        // nonce: await getChallengeCode(), // coming later
        nonce: 'placeholder',
        issuedAt: new Date(Date.now()).toISOString(),
        expirationTime: new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(), // 24 hours from now
      });

      // Sign the SIWE message
      const signature = await signer.signMessage(message.prepareMessage());

      console.log('signature: ' + signature)

      console.log("signing in with inviteCode: " + inviteCode)
      await loginOrRegister(message.toMessage(), signature, inviteCode);

    } catch (error) {
      console.error('Error during login referral:', error)
    } finally {
      setLoading(false);
    }
  }

  const loginOrRegister = async (message, signature, inviteCode) => {
    try {
      const response = await axios.post('https://points.zkcandy.io', {
        message: message,
        signature: signature,
        inviteCode: inviteCode,
        action: 'loginOrRegister'
      });
      console.log('response: ' + JSON.stringify(response))
      console.log("message: " + message)
      console.log("signature: " + signature)
      console.log("inviteCode: " + inviteCode)

      if (response.data && response.data.data.token) {
        if(inviteCode) {
          console.log('successfully registered and logged in with invite code');
        }
        console.log('JWT Token:', response.data.data.token);
        login(response.data.data.token)
      } else {
        console.log('Login failed or no token received');
      }

    } catch (error) {
      // Trigger invite link error
      setModal(prevState => ({
        ...prevState,
        inviteLinkError: true,
      }));

      // Trigger invite code error
      if (formData.code) {
        setModal(prevState => ({
          ...prevState,
          inviteCodeError: true,
        }));
      }
      console.error('Error during login/register:', error);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('invite-code');
    if (code) {
      console.log('invite code: ' + code)
      setInviteCode(code);
    }

    // Display error message for 3 seconds
    if (modal.inviteCodeError) {
      const timer = setTimeout(() => {
        setModal(prevState => ({
          ...prevState,
          inviteCodeError: false,
        }));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [location, modal.inviteCodeError]);

  const buttonStyle = {
    fontSize: '3rem',
    padding: '1rem 2rem'
  };



  return (
    <main className={`login flex justify-center items-center ${loading ? 'loading' : ''}`}>
      {!isConnected ? (
        <button className='btn' style={buttonStyle} onClick={connectWallet}>Connect Wallet</button>
      ) : (
        <button
          style={buttonStyle}
          className='btn btn-yellow'
          onClick={() => loginReferral()}
        >
          Login
        </button>
      )}

      <div className={`${modal.inviteLinkError ? '' : 'hidden'} modal modal-login overflow-y-auto overflow-x-hidden fixed top-0 flex z-50 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-full`}>
        <div className={`${modal.inviteCodeError ? 'modal-blurred' : ''} relative p-4 w-full md:w-1/2 lg:w-1/3 max-w-2xl max-h-full`}>
          <div className='absolute right-0 top-0 z-30'>
            <button type='button' className='btn btn-secondary btn-close relative' onClick={() => closeModal()}>
              <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6' />
              </svg>
            </button>
          </div>
          <div className='modal-bg text-center'>
            <h1 className='mb-10'>Invite-only access</h1>
            <p>Please log in using invite link for <br />first-time users</p>
          </div>
          <div className='flex justify-center'>
            <div className='separator'>
              OR
            </div>
          </div>
          <div className='modal-bg text-center'>
            <form onSubmit={handleInviteCodeSubmit}>
              <input className='input-code m-auto text-center' type='text' name='code' value={formData.code} onChange={handleInviteCodeChange} placeholder='Invite Code' />
              <button type='submit' className='btn m-auto mt-5'>Login with Invite Code</button>
            </form>
          </div>
        </div>

        <div className={`${modal.inviteCodeError ? 'show' : ''} modal-bg code-error text-center m-5`}>
          <div className='mb-4'>Invalid</div>
          <div>Invite Code</div>
        </div>
      </div>

    </main>
  );
}