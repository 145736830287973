import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = '47a791333932335e209c093a40bd5392'

// 2. Set chains
// const defaultNetwork = {
//   chainId: 302,
//   name: 'zkCandy Sepolia Testnet',
//   currency: 'ETH',
//   explorerUrl: 'https://sepolia.explorer.zkcandy.io/',
//   rpcUrl: 'https://sepolia.rpc.zkcandy.io',
// };

const defaultNetwork = {
  chainId: 302,
  name: 'zkCandy Sepolia Testnet',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.explorer.zkcandy.io',
  rpcUrl: 'https://sepolia.rpc.zkcandy.io',
};

// 3. Create a metadata object
const metadata = {
  name: 'zkCandy Points System',
  description: 'A point system to keep track users points',
  url: 'https://portal.zkcandy.io',
  icons: ['https://portal.zkcandy.io/favicon.ico'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata
})

// 5. Create a Web3Modal instance
const web3Modal = createWeb3Modal({
  ethersConfig,
  chains: [defaultNetwork],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export default web3Modal;