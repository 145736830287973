import React, { useEffect, useState } from 'react';
import Img from '../Layout/Img';  // Adjust the import path as needed
import ZcToken from '../images/zc-token.png';  // Add this import
import TableCarousel from '../Components/TableCarousel';
import { useWallet } from '../Context/WalletContext';  // Import the WalletContext hook
import axios from "axios";

import GalaxeLogo from '../images/galaxeLogo.png';
import UsergroupIcon from '../images/icon-usergroup.png';
import GameEVM from '../images/portal/game-evm-png.png';
import GameChooChoo from '../images/portal/game-choochoo-png.png';
import GameSugarRush from '../images/portal/game-sugarrush-png.png';

export default function Detail() {
  const { walletAddress, isConnected } = useWallet();  // Access the wallet context
  const [points, setPoints] = useState(0);
  const [questPoints, setQuestPoints] = useState({});
  const [gamePoints, setGamePoints] = useState({});

  const sendRequest = async (data, url, method = "post", params = null) => {
    let config = {
      method: method,
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (data) config.data = JSON.stringify(data);
    if (params) config.params = params;
    console.debug("config: ", config);
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected && walletAddress) {
        try {
          // Fetch total points
          const totalPointsResponse = await sendRequest(
            {
              action: "getTotalPoints",
              walletAddress: walletAddress,
            },
            `${process.env.REACT_APP_API_URL}`
          );
          console.log('totalPointsResponse: ' + JSON.stringify(totalPointsResponse.data.data));
          if (totalPointsResponse.status === 200 && totalPointsResponse.data) {
            setPoints(totalPointsResponse.data.data.totalPoints || 0);
          }

          // Fetch quest points
          const questNames = ["Voyager's NFT Expedition", "Points Frenzy Challenge", "Joining Bonus"];
          const questPointsData = {};
          for (const questName of questNames) {
            if (questName === "Joining Bonus") {
              const referralResponse = await sendRequest(
                {
                  action: "getReferralPoints",
                  walletAddress: walletAddress,
                },
                `${process.env.REACT_APP_API_URL}`
              );
              if (referralResponse.status === 200 && referralResponse.data) {
                console.log('referralResponse: ' + JSON.stringify(referralResponse.data.data));
                questPointsData[questName] = referralResponse.data.data.totalReferralPoints || 0;
              }
            } else {
              const questResponse = await sendRequest(
                {
                  action: "getUserPointsByQuest",
                  walletAddress: walletAddress, 
                  questName: questName,
                },
                `${process.env.REACT_APP_API_URL}`
              );
              if (questResponse.status === 200 && questResponse.data) {
                console.log('questResponse: ' + JSON.stringify(questResponse.data.data));
                questPointsData[questName] = questResponse.data.data || 0;
              }
            }
          }
          setQuestPoints(questPointsData);

          // Fetch game points
          const gameNames = ["EVM Warfare", "Sugar Rush", "Choo Choo Spirits"];
          const gamePointsData = {};
          for (const gameName of gameNames) {
            const gameResponse = await sendRequest(
              {
                action: "getUserPointsByGame",
                walletAddress: walletAddress,
                gameName: gameName,
              },
              `${process.env.REACT_APP_API_URL}`
            );
            if (gameResponse.status === 200 && gameResponse.data) {
              gamePointsData[gameName] = gameResponse.data.data || 0;
            }
          }
          setGamePoints(gamePointsData);

        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [isConnected, walletAddress]);

  // Predefined quest details
  const detailsDataTemplate = {
    headerTitle: 'Quests Points',
    headerTitleColor: '#FFCC5A',
    // headerSubTitle: 'Milestone',
    // headerSubTitle2: 'Points/Max',
    tableType: 'main',
    tableSubHeader: 'Begin your Deez Journey now',
    tableSubHeaderLink: '/quests',
    tableSubHeaderButtonText: 'Quests',
    data: [
      {
        textColor: '',
        icon: GalaxeLogo,
        title: 'Voyager\'s NFT Expedition',
        // subTitle: 'Follow and retweet on @Zkcandyhq on X [Twitter], join our Telegram and Discord, and mint an NFT in Sugar Rush to earn your Voyager Pass NFT and Candy Points.',
        milestone: 140,
        point: 0,
        maxPoint: 140
      },
      {
        icon: GalaxeLogo,
        textColor: '',
        title: 'Points Frenzy Challenge',
        // subTitle: 'Play our exciting ZKcandy games like Sugar Rush, EVM Warfare, and Choo Choo Spirits to climb the leaderboards and earn Candy Points!',
        milestone: 140,
        point: 0,
        maxPoint: 140
      },
      {
        icon: GalaxeLogo,
        textColor: '',
        title: 'Joining Bonus',
        // subTitle: 'Play our exciting ZKcandy games like Sugar Rush, EVM Warfare, and Choo Choo Spirits to climb the leaderboards and earn Candy Points!',
        milestone: 140,
        point: 0,
        maxPoint: 140
      },
      // {
      //   textColor: '#FFCC5A',
      //   icon: UsergroupIcon,
      //   title: 'ZKcandy Homie',
      //   subTitle: 'Refer a friend who earns 1,000 Quests points this season',
      //   milestone: 200,
      //   milestoneDesc: '/Friend',
      //   point: 0,
      //   maxPoint: 5000,
      // }
    ]
  };

  // Update quest details
  const updatedDetailsData = {
    ...detailsDataTemplate,
    data: detailsDataTemplate.data.map((quest) => ({
      ...quest,
      point: questPoints[quest.title] || 0
    }))
  };

  const gamesData = {
    // headerTitle: 'Game Points',
    // headerTitleColor: '',
    // headerSubTitle: 'Milestone',
    // headerSubTitle2: 'Points/Max',
    tableType: 'main',
    tableSubHeader: 'Game Points',
    tableSubHeaderLink: '/#game',
    tableSubHeaderButtonText: 'Games',
    tableSubHeaderInfo: 'Game points are updated once every week on Monday. Automatic updates are coming soon.',
    data: [
      // {
      //   icon: UsergroupIcon,
      //   textColor: '#FFCC5A',
      //   title: 'BTFD.WTF',
      //   subTitle: 'Refer a friend who earns 5,000 Quests points this season',
      //   milestone: 500,
      //   milestoneDesc: '/Friend',
      //   point: 0,
      // },
      {
        textColor: '#FFCC5A',
        icon: GameEVM,
        title: 'EVM Warfare',
        // subTitle: 'Refer a friend who earns 17,000 Quests points this season',
        milestone: 1500,
        milestoneDesc: '/Friend',
        point: 0,
        maxPoint: 7500,
      },
      {
        textColor: '#FFCC5A',
        icon: GameSugarRush,
        title: 'Sugar Rush',
        // subTitle: 'Complete the collection of all 8 Sugar Rush Candy NFTs.',
        milestone: 100,
        milestoneDesc: '/Candy NFT',
        point: 0,
        maxPoint: 7500,
      },
      {
        textColor: '#FFCC5A',
        icon: GameChooChoo,
        title: 'Choo Choo Spirits',
        // subTitle: 'Complete the collection of all 8 Sugar Rush Candy NFTs.',
        milestone: 100,
        milestoneDesc: '',
        point: 0,
        maxPoint: 7500,
      }
    ]
  };

  // Update game details
  const updatedGamesData = {
    ...gamesData,
    data: gamesData.data.map((game) => ({
      ...game,
      point: gamePoints[game.title] || 0
    }))
  };

  return (
    <div className='tab-details'>
      {/* <div className="banner bg-[#35158484] flex justify-center">
        <div className="container relative">
          <div className="title text-left lg:text-center hidden md:block">
            <div className="">ACCUMULATED</div>
            <div className="">POINTS</div>
          </div>

          <div className="points">
            <div className="flex items-center gap-2">
              <div className="token">
                <Img
                  src={ZcToken}
                  alt="logo"
                  className="w-100"
                />
              </div>
              <div className="value">
                <div className="title text-left block md:hidden">
                  <div className="">ACCUMULATED POINTS</div>
                </div>
                {points}
                <div className="sub-title">Candy Points</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container m-auto">
        <TableCarousel tableData={updatedDetailsData} />
        <TableCarousel tableData={updatedGamesData} />
      </div>
    </div>
  );
}
