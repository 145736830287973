import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is imported
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useAuth } from '../Contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Img from "../Layout/Img";
import IconCopy from '../images/icon-copy.svg';

export default function Referral() {
  const [referralCode, setReferralCode] = useState([]);
  const [referralError, setReferralError] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [canGenerate, setCanGenerate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [numberOfActive, setNumberOfActiveReferrals] = useState(0);
  const { address, chainId, isConnected } = useWeb3ModalAccount();  // Access the wallet context
  const { isKOL } = useAuth();

  const sendRequest = async (data, url, method = "post", params = null) => {
    let config = {
      method: method,
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (data) config.data = JSON.stringify(data);
    if (params) config.params = params;
    console.debug("config: ", config);
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  };

  const fetchNumberOfReferrals = async () => {
    try {
      const response = await sendRequest(
        { action: isKOL ? "getCode" : "getCodes", walletAddress: address },
        `${process.env.REACT_APP_API_URL}`
      );
      console.log("getCode response: ", response);
      if (isKOL) {
        if (response.status === 200 && response.data.data) {
          setNumberOfActiveReferrals("Unlimited");
        }
      } else if (response.status === 200 && response.data) {
        const numberOfCodes = response.data.data.activeCodes.length;
        console.log("Number of codes: ", numberOfCodes);
        if (numberOfCodes > 18) {
          setNumberOfActiveReferrals("Unlimited");
        } else {
          setNumberOfActiveReferrals(numberOfCodes);
        }
      } else {
        setNumberOfActiveReferrals(0);
        console.error("Failed to fetch number of codes");
      }
    } catch (error) {
      console.error("Error fetching number of codes:", error);
      setNumberOfActiveReferrals(0);
    }
  };

  const fetchReferralCode = async () => {
    setLoading(true);
    try {
      console.log('fetching data')
      const response = await sendRequest(
        { action: isKOL ? "getCode" : "getCodes", walletAddress: address },
        `${process.env.REACT_APP_API_URL}`
      );

      console.log("getcode response data: ", response.data);
      // Assuming the API returns the referral link in response.data.link
      setReferralCode(isKOL ? response.data.data : response.data.data.activeCodes.slice(0, 8));

    } catch (error) {
      console.error("Error fetching referral link:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReferrals = async () => {
    try {
      console.log('fetching referrals');

      const response = await sendRequest(
        { action: "getReferrals", walletAddress: address },
        `${process.env.REACT_APP_API_URL}`
      );

      console.log("getReferrals response data: ", response.data.data.referrals);
      //Sort the array by referredAt in descending order
      const sortedReferrals = response.data.data.referrals.sort((a, b) => b.referredAt - a.referredAt).map(item => ({
        ...item,
        referredAt: new Date(item.referredAt).toLocaleString() // Convert timestamp to readable format
      }));

      setReferrals(sortedReferrals)
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  }

  const addMoreInviteCodes = async () => {
    try {
      const response = await sendRequest(
        { action: "addMoreInviteCodes", walletAddress: address },
        `${process.env.REACT_APP_API_URL}`
      );

      console.log("addCode response data: ", response.data);

      // If not enough point
      if (response.data.data.message) {
        setReferralError("Can't create any more codes right now. Earn more points to create more codes.")
        setCanGenerate(false);
      } else {
        fetchReferralCode();
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchNumberOfReferrals();  // Fetch the number of referrals when the component mounts
    fetchReferralCode();
    fetchReferrals();

    if (!referralCode) {
      setReferralError('Invitation codes have been fully redeemed.');
    }
  }, [address]);

  const shareReferralCode = (index) => {
    // fetchReferralCode();
    if (referralCode.length > 0 || referralCode) {

      if (isKOL) {
        const shareCode = `${referralCode}`;
        navigator.clipboard.writeText(shareCode);
      } else {
        const shareCode = `${referralCode[index].code}`;
        navigator.clipboard.writeText(shareCode);
      }
      toast.success('Referral code copied');
      // alert("Referral code copied!");
    } else {
      toast.error('Referral code is not available.');
      // alert("Referral code is not available.");
    }
  };

  const shareReferralLink = (index) => {
    // fetchReferralCode();
    if (referralCode.length > 0 || referralCode) {

      if (isKOL) {
        const shareUrl = `${window.location.origin}/login?invite-code=${referralCode}`;
        navigator.clipboard.writeText(shareUrl);
      } else {
        const shareUrl = `${window.location.origin}/login?invite-code=${referralCode[index].code}`;
        navigator.clipboard.writeText(shareUrl);
      }
      toast.success('Referral link copied');
      // alert("Referral link copied!");
    } else {
      toast.error('Referral link is not available.');
      // alert("Referral link is not available.");
    }
  };

  const copyAllReferralCodes = () => {
    if (referralCode.length > 0) {
      const shareCode = referralCode.map(item => item.code).join('\n \n');
      navigator.clipboard.writeText(shareCode);

      navigator.clipboard.writeText(shareCode);
      toast.success('All referral code copied');
    } else {
      toast.error('Referral code is not available.');
    }
  }

  return (
    <div className="referral">
      <ToastContainer theme='dark' />
      <div className="container m-auto">
        <div className="panel active-referral flex justify-between">
          <div className="left md:w-[80%] w-full">
            <div className="title">
              DEEZ REFER DEEZ
            </div>
            <div className="sub-title font-montserrat">
              <div className="">
                Referrers will receive <span className="text-[#FFCC5A]">10%</span> of the points earned by their direct referees and <span className="text-[#FFCC5A]">5%</span> from the referees referred earned by the referee.
              </div>
            </div>
          </div>

          <div className="referral">
            <div className={`${isKOL ? 'text-[4rem]' : 'text-[7rem]'} value tracking-[-1rem]`}>{numberOfActive}</div>
            <div className="sub-title">Remaining Referrals</div>
          </div>
        </div>

        <div className="panel code">
          <div className="sub-title">your code</div>
          {referralCode && (
            <div>
              {isKOL ? (
                <div className='flex justify-start items-center flex-wrap'>
                  <button
                    className="btn btn-code me-3  mb-3 md:mb-0"
                    onClick={() => shareReferralCode()}
                    disabled={loading}
                  >
                    {referralCode}
                  </button>
                  <button className='btn btn-secondary me-3 -mb-1'
                    onClick={() => shareReferralCode()}
                    disabled={loading}>
                    <div className="w-[1.5rem] pe-1">
                      <Img src={IconCopy} />
                    </div>
                    <span className='-mb-1'>Code</span>
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => shareReferralLink()}
                    disabled={!referralCode}
                  >
                    <div className="w-[1.5rem] pe-1">
                      <Img src={IconCopy} />
                    </div>
                    <span className="-mb-1">
                      Link
                    </span>
                  </button>
                </div>
              ) : (
                <div className="grid md:grid-cols-[auto_1fr] gap-3 md:gap-4">
                  {referralCode.map((codeObj, index) => (
                    <React.Fragment key={index}>
                      <button
                        key={index}
                        className="btn btn-code me-0 md:me-3 w-full justify-center"
                        onClick={() => shareReferralCode(index)}
                        disabled={loading}
                      >
                        {codeObj.code}
                      </button>
                      <div className="flex justify-start mb-2 md:mb-0">
                        <button className='btn btn-secondary me-3'
                          onClick={() => shareReferralCode(index)}
                          disabled={loading}>
                          <div className="w-[1.5rem] pe-1">
                            <Img src={IconCopy} />
                          </div>
                          <span className='-mb-1'>Code</span>
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={() => shareReferralLink(index)}
                          disabled={!referralCode}
                        >
                          <div className="w-[1.5rem] pe-1">
                            <Img src={IconCopy} />
                          </div>
                          <span className="-mb-1">
                            Link
                          </span>
                        </button>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          )}

          {referralCode.length > 1 && !isKOL && (
            <button className="btn btn-yellow mt-10"
              onClick={() => copyAllReferralCodes()}>
              <span className="-mb-1 text-[2.5rem] tracking-[-.5rem]">
                Copy All Codes
              </span>
            </button>
          )}

          {!referralCode && (
            <div className="mt-5">
              <div className='referral-error-msg'>{referralError}</div>

              {canGenerate && (
                <button
                  className="btn btn-code"
                  onClick={addMoreInviteCodes}
                >
                  Generate More Codes
                </button>
              )}
            </div>
          )}

          <div className='mt-5 font-montserrat'>
            {isKOL ? (
              <p>
                Here is your exclusive KOL referral code that can be used unlimited number of times.
              </p>
            ) : (
              <p>
                You initially get 8 referral codes that can each be used once. You may earn more referral codes by achieving candypoints milestones.
              </p>
            )}
          </div>
        </div>

        {referrals.length > 0 && (
          <div className="invite">
            <div className="title">Your Invites</div>
            <div className="panel">
              <table className="table-invite">
                <thead>
                  <tr>
                    <th>Addresses</th>
                    <th>Tiers</th>
                    <th>Conversion Date</th>
                  </tr>
                </thead>
                <tbody>
                  {referrals.map((item, index) => (
                    <tr key={index}>
                      <td>{item.walletAddress}</td>
                      <td>{item.tier}</td>
                      <td>{item.referredAt}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
