import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider } from 'ethers'
import { SiweMessage } from 'siwe';
import Img from "../Layout/Img";
import Referral from "./Referral";
import LeaderBoard from "./Leaderboard";
import Detail from "./Detail";
import './my-points.scss';
import ZcToken from '../images/zc-token.png';  // Add this import

// Add these imports
import CandyPointAvatar from '../images/user-avatar.png';
import { useWallet } from '../Context/WalletContext'; // Assuming you have a WalletContext for managing wallet state
import axios from 'axios';

export default function MyPoints() {
  const [selectId, setSelectId] = useState(1);
  const [inviteCode, setInviteCode] = useState('');
  const [totalPoints, setTotalPoints] = useState(0); // State to store the total points
  const location = useLocation();
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const { walletAddress } = useWallet(); // Assuming you have a wallet context to get the user's wallet address
  const navigate = useNavigate();

  const selectList = [
    {
      code: 1,
      name: "Details"
    },
    {
      code: 2,
      name: "Leaderboard"
    },
    {
      code: 3,
      name: "Referrals"
    },
  ];

  const loginReferral = async () => {

    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()

    try {
      //create the siwe message
      const message = new SiweMessage({
        domain: window.location.origin,
        address: address,
        statement: 'Sign in with Ethereum to zkCandy Points Portal',
        uri: "https://portal.zkcandy.io",
        version: '1',
        chainId: "302",
        // nonce: await getChallengeCode(), // coming later
        nonce: 'placeholder',
        issuedAt: new Date(Date.now()).toISOString(),
        expirationTime: new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(), // 24 hours from now
      });

      // Sign the SIWE message
      const signature = await signer.signMessage(message.prepareMessage());

      console.log('signature: ' + signature)

    } catch (error) {
      console.log(error)
    }
  }

  const sendRequest = async (data, url, method = "post", params = null) => {
    let config = {
      method: method,
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (data) config.data = JSON.stringify(data);
    if (params) config.params = params;
    console.debug("config: ", config);
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  };

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }
  }, [isConnected, navigate]);
  
  useEffect(() => {
    // Fetch invite code from URL if available
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('invite-code');
    if (code) {
      console.log('invite code: ' + code)
      setInviteCode(code);
    }

    // Fetch user's total points
    const fetchTotalPoints = async () => {
      if (walletAddress) {
        try {
          const response = await sendRequest(
            { action: "getTotalPoints", walletAddress: walletAddress },
            `${process.env.REACT_APP_API_URL}`
          );

          if (response.status === 200 && response.data) {
            setTotalPoints(response.data.data.totalPoints || 0);
          } else if (response.status === 404) {
            setTotalPoints(0); // Set points to 0 if not found
          }
        } catch (error) {
          console.error("Error fetching total points:", error);
          setTotalPoints(0); // Handle error by setting points to 0
        }
      }
    };

    fetchTotalPoints();

    window.scrollTo(0, 0);
  }, [location, walletAddress]);

  return (
    <main className={`my-points tracking-tighter text-white font-normal leading-7`}>
      <div className={`text-banner flex justify-center`}>
        <div className={`container flex justify-center text-center`}>
          Compete for 100 mil Candy Points in Season Zero
        </div>
      </div>

      <div className="text-season flex justify-center items-center">
        <div className="text-[#FFCC5A]">Season Zero</div>
      </div>

      <div className="user-info flex justify-center">
        <div className="container item-between">
          <div className="user-data">
            <div className="avatar">
              <Img
                src={CandyPointAvatar}
                alt="avatar"
                className=""
              />
            </div>

            <div className="points">
              <div className="token flex items-center">
                <div className="w-[5rem]">
                  <Img
                    src={ZcToken}
                    alt="logo"
                    className="w-100"
                  />
                </div>
                <div className="candy-point">
                  <div className="title ms-2">Candy points</div>
                  <div className="value">{totalPoints}</div> {/* Display the fetched total points */}
                </div>
              </div>
              {/* <div className="multiplier">
                <div className="title">NFT MULTIPLIER</div>
                <div className="value">110%</div>
              </div> */}
            </div>
          </div>
          <div className="tabs-header flex gap-10">
            {
              selectList && selectList.map(i =>
                <div className={`cursor-pointer  ${selectId === i.code && 'border-b-[3px] border-[#FD00CA]'}`}
                  key={i.code}
                  onClick={() => { setSelectId(i.code) }}
                >
                  <div className="">{i.name}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div>
        {selectId === 1 ? <Detail /> : selectId === 2 ? <LeaderBoard /> : <Referral />}
      </div>
    </main>
  );
}
