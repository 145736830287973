import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Contexts/AuthContext'; 
import ProtectedRoute from './Components/ProtectedRoute'; 
import Home from './Home';
import Quests from './Quests/Quests';
import MyPoints from './MyPoints/MyPoints';
import Layout from './Layout/Layout'
import Login from './Login/Login'

//Normal way to route

function App() {
  return (
    
    <Router>
    <AuthProvider>
      <Routes>
      <Route element={<Layout />}>
        <Route element={<ProtectedRoute />}>
            <Route path="my-points" element={<MyPoints />} />
            <Route path="quests" element={<Quests />} />         
        </Route>
        
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
      </Route>
      </Routes>
    </AuthProvider>
    </Router>
    
  );
}

export default App;



