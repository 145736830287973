import React from "react";
// import Img from "./Img";
import FooterLogo from '../images/footer-logo.png';
import Xlogo from '../images/x-logo.png';
import DiscordLogo from '../images/discord-logo.png';
import MediumLogo from '../images/icon-medium.svg';
import TelegramLogo from '../images/icon-telegram.svg';

export default function Footer() {
  return (
    <div className="footer">
      <div className="container m-auto">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-5">
            <a href="https://zkcandy.io/" target="_blank" className="logo">
              <img src={FooterLogo} alt="ZKcandy" className="" />
            </a>
            <a href="https://zkcandy.io/" target="_blank" className="copyright">
              Copyright <span className="symbol">&#169;</span> 2024 ZKcandy
            </a>
          </div>
          <div className="social-media flex gap-2 items-center">
            <a href="https://x.com/ZKcandyHQ" target="_blank" className="icon x">
              <img
                src={Xlogo}
                alt="x-logo"
                className=""
              />
            </a>
            <a href="https://discord.com/invite/zkcandy" target="_blank" className="icon discord">
              <img
                src={DiscordLogo}
                alt="Discord"
                className=""
              />
            </a>
            <a href="https://t.me/zkcandy" target="_blank" className="icon telegram">
              <img
                src={TelegramLogo}
                alt="Telegram"
                className=""
              />
            </a>
            <a href="https://medium.com/@zkcandy" target="_blank" className="icon medium">
              <img
                src={MediumLogo}
                alt="Medium"
                className=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}