import React, { useState, useRef, useEffect } from 'react';
import Logo from '../images/logo.png';
import Menu from '../images/menu.png';
import Close from '../images/close.png';
import { Link, useLocation } from "react-router-dom";
import { useWallet } from '../Context/WalletContext';  // Import the WalletContext hook

export default function Header() {
  const { walletAddress, isConnected, connectWallet } = useWallet();  // Access the wallet context

  const location = useLocation();
  // const showMenuButton = location.pathname !== '/' && location.pathname !== '/login';
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown menu

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Attach and clean up the event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="header flex justify-center">
      <div className="container items-center justify-between lg:flex hidden">
        <div className="flex items-center gap-6 text-[1.8rem]">
          <Link to="/" className="logo me-5">
            <img
              src={Logo}
              alt="logo"
              className=""
            />
          </Link>
          {location.pathname != '/login' && (
            <Link to="/quests">
              <div className={location.pathname === '/quests' ? 'page-title active' : 'page-title'}>
                Quests
                {/* {location.pathname === '/my-points' ? "My Points" : location.pathname.slice(1)} */}
              </div>
            </Link>
          )}
          {location.pathname != '/login' && (
            <Link to="/my-points">
              <div className={location.pathname === '/my-points' ? 'page-title active' : 'page-title'}>
                My Points
                {/* {location.pathname === '/my-points' ? "My Points" : location.pathname.slice(1)} */}
              </div>
            </Link>
          )}
        </div>

        {location.pathname != '/login' && (
          <div className="flex items-center text-[2rem] menus gap-6">
            <a href="/#game" className='menu'>Games</a>
            <a href="/#tools" className='menu'>Tools</a>
            <a href="https://faucet.zkcandy.io/" className='menu'>Faucet</a>

            {!isConnected && (
              <button className='btn-connect' onClick={connectWallet}>Connect Wallet</button>
            )}
            {isConnected && (
              <w3m-button />
            )}
          </div>
        )}
      </div>

      <div className="relative inline-block text-left lg:hidden flex justify-between w-full">
        <Link to="/" className="logo ms-5">
          <img
            src={Logo}
            alt="logo"
            className=""
          />
        </Link>
        <button
          onClick={toggleDropdown}
          className="px-4 py-2"
        >
          <div className="btn-menu">
            <img
              src={Menu}
              alt="logo"
              className=""
            />
          </div>
        </button>
        {isOpen && (
          <div
            ref={dropdownRef}
            className="dropdown absolute top-[-2rem] rounded-md shadow-lg bg-[#00000acc] w-full h-[100svh] z-10 flex justify-center transition-all"
          >
            <div className="p-1 flex justify-center flex-col items-center gap-8 text-[2.5rem]">
              {location.pathname != '/login' && (
                <Link to="/quests" onClick={toggleDropdown}>
                  <div className={location.pathname === '/quests' ? 'page-title active' : 'page-title'}>
                    Quests
                    {/* {location.pathname === '/my-points' ? "My Points" : location.pathname.slice(1)} */}
                  </div>
                </Link>
              )}
              {location.pathname != '/login' && (
                <Link to="/my-points" onClick={toggleDropdown}>
                  <div className={location.pathname === '/my-points' ? 'page-title active' : 'page-title'}>
                    My Points
                    {/* {location.pathname === '/my-points' ? "My Points" : location.pathname.slice(1)} */}
                  </div>
                </Link>
              )}
              <a href="/#game" className='menu' onClick={toggleDropdown}>Games</a>
              <a href="/#tools" className='menu' onClick={toggleDropdown}>Tools</a>
              <a href="https://faucet.zkcandy.io/" className='menu'>Faucet</a>

              {!isConnected && (
                <button className='btn-connect' onClick={connectWallet}>Connect Wallet</button>
              )}
              {isConnected && (
                <w3m-button />
              )}

              <div className="w-[30px] mt-10" onClick={toggleDropdown}>
                <img
                  src={Close}
                  alt="logo"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}