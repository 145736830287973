import React, { useState, useEffect } from 'react';
import ZcToken from '../images/zc-token.png';  // Add this import
import axios from 'axios';

export default function LeaderBoard() {
    const [selectWeekId, setSelectWeekId] = useState(3);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true); // Add a loading state

    const selectWeek = [
        {
            key: 1,
            name: 'WEEK ONE'
        },
        {
            key: 2,
            name: 'WEEK TWO'
        },
        {
            key: 3,
            name: 'WEEK THREE'
        }
    ];

    useEffect(() => {
        fetchLeaderboardData(selectWeekId);
    }, [selectWeekId]);

    const fetchLeaderboardData = async (week) => {
        setLoading(true);
        try {
            const response = await sendRequest(
                {
                    action: "getTopWalletsByPoints",
                    limit: 50 // Adjust the limit as needed
                },
                `${process.env.REACT_APP_API_URL}`
            );

            console.log("leaderboard response: ", response);
            if (response.status === 200 && response.data) {
                setList(response.data.data.wallets);
            } else {
                console.error('Failed to fetch leaderboard data:', response.status);
            }
        } catch (err) {
            console.error('Error fetching leaderboard data:', err);
        } finally {
            setLoading(false);
        }
    };

    const sendRequest = async (data, url, method = "post", params = null) => {
        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        if (data) config.data = JSON.stringify(data);
        if (params) config.params = params;
        console.debug("config: ", config);
        return await axios.request(config).catch(function (error) {
            if (error.response) {
                return error.response;
            } else {
                return error;
            }
        });
    };

    function chooseWeek(e) {
        setSelectWeekId(e);
    }

    return (
        <div className="leaderboard">
            <div className="container m-auto">
                <div className="tab-week">
                    {/* {selectWeek && selectWeek.reverse().map(i =>
                        <div 
                            className={`tab-item cursor-pointer ${selectWeekId === i.key ? 'bg-[#9057ffbd]' : "bg-[#9057ff54]"}`} 
                            key={i.key}
                            onClick={() => chooseWeek(i.key)}
                        >
                            {i.name}
                        </div>
                    )} */}
                </div>
                {loading ? (
                    <div>Loading...</div> // Display loading state while fetching data
                ) : (
                    <div className="leaderboard-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Total Candy Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list && list.map((i, index) =>
                                    <tr key={index}>
                                        <td>#{index + 1}</td>
                                        <td width="50%">{i.walletAddress}</td>
                                        <td>
                                            <div className="flex items-center justify-center">
                                                <img alt="" src={ZcToken} className="token" />
                                                <span className='w-1/4 text-start'>
                                                    {Math.floor(i.points)}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}